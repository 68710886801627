<template>
  <div id='questShow'>
    <div class='div_main'>      
      <template v-if="loading">
        <br>
        <br>
        <br>
        <a-empty />
      </template>
      <div class='div_midn' v-if="!loading">
        <div class='div_left'> 
          <div class='div_cont' >
            <div class='div_item' v-html="gtdata.cap_content">
            </div>
          </div>
          <div class='div_cont' v-if="gtdata.show_type>1">
            <div class='div_item' v-html="gtdata.quest_content_Show">
            </div>
          </div>        
          <div class='div_cont' v-for="(item,index) in chartDisp" :key='"ec"+index'>
            <div class='div_item'>
              <chartset :transdata="item" />
            </div>
          </div> 
          <div class='div_cont' v-for="(item,index) in canvasDisp" :key='"cc"+index'>
            <div class='div_item'>
              <canvaset :transdata="item" />
            </div>
          </div>

          <div class='div_item' v-if="gtdata.show_type>=4 && (gtdata.type_id==22 || gtdata.type_id==23)">
            <p v-if="gtdata.type_id==22"><SoundOutlined :style="{fontSize: '20px',color: 'blue'}" /> {{ promptSingle }}</p>
            <p v-if="gtdata.type_id==23"><SoundOutlined :style="{fontSize: '20px',color: 'blue'}" /> {{ promptMore }}</p>
            <div class='div_answBox' v-if="gtdata.show_type>=4 && (gtdata.type_id==22 || gtdata.type_id==23)">
              <div v-for="(item,index) in gtdata.act_items" :key='"ca"+index' class='div_answItem' >
                <div class='div_answLabl'><b>{{ prefixOpt[index] }}</b></div>
                <div class='div_answCanv' :class="{answSlcted:gtdata.act_items.length>index ? gtdata.act_items[index].answer_show == 'Y' ? true : false : false }">
                  <canvaset :transdata="canvasAnsw[item.content]" />
                </div>
              </div>
            </div>
          </div>
          <div class='div_cont' v-show="gtdata.resolve!='0'">            
            <div class="slut_title">
              <div class="le"></div><div class="mi">Solution</div><div class="ri"></div>
            </div>
            <div class='div_item slut' v-html="gtdata.resolve_Show">
            </div>
            <div class='div_item' v-for="(item,index) in canvasSolv" :key='"cs"+index'>
              <canvaset :transdata="item" />
            </div>
          </div>          
          <div id="btnSpShow">
            <a-button v-show="intSplitTimes>1" :type="intSplitErrs > 0 ? 'danger' : 'primary'" size="large" shape="circle" @click="bolShowPop=!bolShowPop">
              {{ intSplitTimes }}
            </a-button>  
          </div>               
        </div>


        <div class='div_right' v-if="gtdata.show_type<4"> 
          <div class='div_cont' v-if="gtdata.show_type==1">
            <div class='div_item' v-html="gtdata.quest_content_Show">
            </div>
          </div>        
          <div class='div_cont'>
            <div class='div_item'> 
              <p v-if="gtdata.type_id==2 && gtdata.show_type==2"><SoundOutlined :style="{fontSize: '20px',color: 'blue'}" /> {{ promptSingle }}</p>
              <p v-if="gtdata.type_id==3 && gtdata.show_type==2"><SoundOutlined :style="{fontSize: '20px',color: 'blue'}" /> {{ promptMore }}</p>
              <div v-for="(item,index) in gtdata.act_items" :key='"act"+index' class='slctdiv' :class="{isslcted:item.answer_show == 'Y'}" >
                <div style="padding:0 12px 0 0;">
                <b>{{ prefixOpt[index] }}</b>
                </div>
                <div>
                {{ fixMjSpace(item.content_Show) }}
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div id='popSplit' v-if="bolShowPop">
      <h3 :style="{color:+ intSplitErrs > 0 ? 'red' : 'black'}">{{ intSplitErrs > 0 ? '✘ ' + intSplitErrs + ' dupilicate answers found !' : '✔ No dupilicate answers found !'}}
      </h3>
      <table id='tbcenter' class='tbsp'>
        <tr v-for="(items,index) in arrShowSplits" :key="'tr'+index" @click="clickSplit(index)" class='slcttr' :style="{backgroundColor:+ intThisSplit==index-1 ? 'lightgreen' : ''}">
          <td v-for="(item,ind2) in items" :key="'td'+ind2" :style="{backgroundColor:+ item.err ? 'red' : ''}">
            {{ item.val }}
          </td>
        </tr>
      </table>
    </div>

  </div>
</template>

<script>
import { CONF } from '../utils/config.js'
import { SoundOutlined } from '@ant-design/icons-vue';
import MathJ from '../utils/MathJax.js'
import chartset from './chartSet.vue'
import canvaset from './canvaSet.vue'
import { create, all } from 'mathjs/number'
var Frac = require('fraction.js');
const math = create(all)

import { marked } from 'marked';
var rendererMD = new marked.Renderer();
marked.use({
  mangle: false,
  headerIds: false
});
marked.setOptions({
  renderer: rendererMD,
  gfm: true,
  tables: true,
});

export default {
  data() {
    return{
      loading: true,
      gtdata: {},
      promptSingle: CONF.promptSingle,
      promptMore: CONF.promptMore,
      MathJax: MathJ,
      chartDisp: [],
      canvasDisp: [],
      chartAnsw: [],
      canvasAnsw: [],
      canvasSolv: [],
      prefixOpt: ['A.','B.','C.','D.','E.','F.','G.','H.','I.','J.'],
      arrSplitItems: [], //for Split use
      intSplitTimes: 1,
      arrShowSplits: [],
      arrThisSplit: [],
      intThisSplit: 0,
      intSplitErrs: 0,
      bolShowPop: false,
      strBOD: "\\(?-?\\d+\\.?\\d*([-+*%/]\\(?-?\\d+\\.?\\d*\\)?)*",
      strFra: "\\(?-?\\d+[. ]?\\d*([-+*%/#]\\(?-?\\d+[. ]?\\d*\\)?)*",
      strNums: "-?\\d+[./]?\\d*(;-?\\d+[./]?\\d*\\)?)*",
    }
  },

  components: {
    chartset,
    canvaset,
    SoundOutlined,
  },

  methods:{
    clickSplit(index){
      if(index>0){
        this.intThisSplit = index - 1;
        this.refreshShow();
      }
    },

    prepContent(content,QorR) {
      if(content){
        let chartnum = 0;
        content = content.replace(/\r\n/g, "\n").replace(/@#([^@#\n]+)#\n([^@#]+)\n#+([^@#]+)\n#([^@#]*)#@/g,(match,p1,p2,p3,p4)=>{
          this.chartDisp.push({
            chnum: chartnum,
            para1: p1,
            para2: p2,
            para3: p3,
            para4: p4,
            qstid: this.gtdata.id,
          })
          chartnum += 1;
          return '';
        }); // chart data splited
        let cavasnum = 0;
        content = content.replace(/(?:@(\d))?@&([^@]+)\n&@/g,(match,p1,p2)=>{
          if(p1){
            this.canvasAnsw[p1]={
              canum : cavasnum,
              cacon : p2,
            }
          }else if(QorR=='Q'){
            this.canvasDisp.push({
              canum : cavasnum,
              cacon : p2,
            }) 
          }else{
            this.canvasSolv.push({
              canum : cavasnum,
              cacon : p2,
            }) 
          }
          cavasnum += 1;         
          return '';
        }); // canvas data splited    

      }
      return this.fixMjSpace(content);
    },

    calcContent(content){
      for(let i=0;i<this.arrThisSplit['DSP'].length;i++){
        if(''+this.arrThisSplit['DSP'][i]){
          let i1 = i+1;
          let strThisSplit = this.arrThisSplit['DSP'][i];
          content = content.replace(/@#[^@]+?#@|@&[^@]+?&@|\$\$.+?\$\$|\$#.+?#\$|<quotespan>.*?<\/quotespan>/g,function(match){  // matched in echarts,canvas,mathjax,quotespan not use span
            return match.replace(RegExp('<D'+i1+'>','g'),strThisSplit); 
          })
          content = content.replace(RegExp('<D'+i1+'>','g'),'<span class="splitspan">'+this.arrThisSplit['DSP'][i]+'</span>'); 
        }
      }
      for(let i=0;i<this.arrThisSplit['ACT'].length;i++){   // for resolve the most time 
        if(''+this.arrThisSplit['ACT'][i]){
          let i1 = i+1;
          let strThisSplit = this.arrThisSplit['ACT'][i];
          content = content.replace(/@#[^@]+?#@|@&[^@]+?&@|\$\$.+?\$\$|\$#.+?#\$|<quotespan>.+?<\/quotespan>/g,function(match){   // matched in echarts,canvas,mathjax,quotespan not use span
            return match.replace(RegExp('<A'+i1+'>','g'),strThisSplit); 
          })
          content = content.replace(RegExp('<A'+i1+'>','g'),'<span class="splitspan">'+this.arrThisSplit['ACT'][i]+'</span>'); 
        }
      }

      return content;
    },

    prepCloze(content){  // 替换cap 里面的空格代号，SS的C1-C6 为15 -20，OC的为12-17
      let clozeNum = 0; 
      for(let i=1;i<=CONF.clozeNums;i++){
        if(this.gtdata.belong=='SS'){
          clozeNum = i - 1 + CONF.clozeStartSS;
        }else{
          clozeNum = i - 1 + CONF.clozeStartOC;
        }
        content = content.replace(RegExp('<█C'+i+'>','g'),'<span class="clozeNumC">'+clozeNum+'</span><span class="clozeDotC">............</span>'); 
      }
      return content;
    },

    splitDSPnACT(dspitems,actitems){
      this.arrSplitItems=[];   // clear
      this.arrShowSplits= [];  // clear
      this.intSplitTimes = 1;  // init

      let arrSplitSimDSP = [];  
      let arrSplitCalDSP = [];
      let arrSplitACT = [];

      let arrTmpNR = []; // storage for tmp NR 
      let arrKeyNR = []; 
      let arrCntNR = []; // storage for NR组内个数
      let arrNowNR = []; // storage for NR组内到达位置
      let arrPosNR = []; // storage for NR组内列出过的位置数组

      this.arrThisSplit['DSP'] = new Array();
      this.arrThisSplit['ACT'] = new Array();

      //pre calculate
      if(dspitems){
        dspitems.forEach((value,index)=>{ // calculate split times
          if(/^RC:([-+*%/.\w]+;)+[-+*%/.\w]+/.test(value.content)){  // RC split = mutiple
            let arrSecond = value.content.replace(/^RC:/,'').split(";");
            this.intSplitTimes = this.intSplitTimes * arrSecond.length;
          }else if(/^NR:([^;]+;)+[^;]+/.test(value.content)){  // NR split 首个 2024-8-1改为P(m,n）
            let arrSecond = value.content.replace(/^NR:/,'').split(";");
            this.intSplitTimes = this.intSplitTimes * arrSecond.length;
            arrTmpNR[index+1] = new Array; 
            arrTmpNR[index+1] = arrSecond;  // NR 分离出来数组，压入NR大数组
            arrKeyNR[index+1] = [... new Array(arrTmpNR[index+1].length).keys()]
            arrCntNR[index+1] = 1; // 该组NR 第1个
          }else if(/^NR:<D[1-9][0-9]?>/.test(value.content)){  // NR split 后续元素
            let thisNrFrom = 0;
            value.content.replace(/^NR:<D([1-9][0-9]?)>$/,(match,p1)=>{
              thisNrFrom = p1;
            })
            this.intSplitTimes = this.intSplitTimes * (arrTmpNR[thisNrFrom].length - arrCntNR[thisNrFrom]);
            arrCntNR[thisNrFrom] += 1;  // 该组NR使用总数，加1个
          }else if(/^OD:([^;]+;)+[^;]+/.test(value.content)){   // OD 
            let arrSecond = value.content.replace(/^OD:/,'').split(";");
            if(this.intSplitTimes==1){
              this.intSplitTimes = arrSecond.length;
            }else if(this.intSplitTimes>arrSecond.length){
              this.intSplitTimes = arrSecond.length;
            }
          }
        })
        this.intSplitTimes = this.intSplitTimes > 2999 ? 2999 : this.intSplitTimes;

        //pre traverse
        let curFactor = 1;
        let donFactor = 1;
        dspitems.forEach((value,n)=>{
          if(/^RC:([-+*%/.\w]+;)+[-+*%/.\w]+/.test(value.content)){  // RC split = mutiple data to array
            if(arrSplitCalDSP[n] == undefined){
              arrSplitCalDSP[n] = new Array;
            }
            let arrSecond = value.content.replace(/^RC:/,'').split(";");
            curFactor = arrSecond.length;
            for(let i=0;i<this.intSplitTimes;i++){
              let calV = parseInt(i % (this.intSplitTimes/donFactor) / (this.intSplitTimes/donFactor/curFactor));
              arrSplitCalDSP[n][i] = arrSecond[calV];
              if(this.intThisSplit==i){ // set page show DSP at chosed number
                this.arrThisSplit['DSP'][n] = arrSecond[calV];
              }
            }
            donFactor = donFactor * curFactor;
          }else if(/^NR:([^;]+;)+[^;]+/.test(value.content)){  // NR Split the first one 
            if(arrSplitCalDSP[n] == undefined){
              arrSplitCalDSP[n] = new Array;
            }
            curFactor = arrTmpNR[n+1].length;
            for(let i=0;i<this.intSplitTimes;i++){
              //arrSplitCalDSP[n][i] = arrTmpNR[n+1][i % curFactor];
              let calV = parseInt(i % (this.intSplitTimes/donFactor) / (this.intSplitTimes/donFactor/curFactor));
              arrSplitCalDSP[n][i] = arrTmpNR[n+1][calV];
              if(this.intThisSplit==i){ // set page show DSP at chosed number
                this.arrThisSplit['DSP'][n] = arrTmpNR[n+1][calV];  // 确定当前选择的数据
              }
              if(arrPosNR[n+1] == undefined){
                arrPosNR[n+1] = new Array;
              }
              arrPosNR[n+1][i] = [calV];  // 该组NR，第i行，处理过的记录，第1个
            }
            donFactor = donFactor * curFactor;
            arrNowNR[n+1] = 1;
          }else if(/^NR:<D[1-9][0-9]?>$/.test(value.content)){  // NR Split the second and more
            if(arrSplitCalDSP[n] == undefined){
              arrSplitCalDSP[n] = new Array;
            }
            let thisNrFrom = 0;
            value.content.replace(/^NR:<D([1-9][0-9]?)>$/,(match,p1)=>{
              thisNrFrom = p1;
            })
            curFactor = arrTmpNR[thisNrFrom].length - arrNowNR[thisNrFrom];
            for(let i=0;i<this.intSplitTimes;i++){
              let calV = parseInt(i % (this.intSplitTimes/donFactor) / (this.intSplitTimes/donFactor/curFactor)); 
              let arrFltNR = arrKeyNR[thisNrFrom].filter(el => !arrPosNR[thisNrFrom][i].includes(el));
              arrSplitCalDSP[n][i] = arrTmpNR[thisNrFrom][arrFltNR[calV]]; //arrFltNR留下的是arrTmpNR原始位置的号
              if(this.intThisSplit==i){ // set page show DSP at chosed number
                this.arrThisSplit['DSP'][n] = arrTmpNR[thisNrFrom][arrFltNR[calV]];  // 确定当前选择的数据
              }                
              arrPosNR[thisNrFrom][i].push(arrFltNR[calV]);  // 该组NR，第i行，处理过的记录，加1个
            }
            donFactor = donFactor * curFactor;
            arrNowNR[thisNrFrom] += 1;
          }else if(/^OD:([^;]+;)+[^;]+/.test(value.content)){  // OD Split = trans string to array
            if(arrSplitCalDSP[n] == undefined){
              arrSplitCalDSP[n] = new Array;
            }
            let arrSecond = value.content.replace(/^OD:/,'').split(";");
            for(let i=0;i<this.intSplitTimes;i++){
              arrSplitCalDSP[n][i] = arrSecond[i];
              if(this.intThisSplit==i){ // set page show DSP at chosed number
                this.arrThisSplit['DSP'][n] = arrSecond[i];
              }
            }
          }
        })
      }

      if(dspitems){
        dspitems.forEach((value,n)=>{
          if(/^RD:([^;]+;)+[^;]+/.test(value.content)){  // RD split = only display
            if(arrSplitSimDSP[n] == undefined){
              arrSplitSimDSP[n] = new Array;
            }
            let arrSecond = value.content.replace(/^RD:/,'').split(";");
            for(let i=0;i<this.intSplitTimes;i++){
              arrSplitSimDSP[n][i] = arrSecond[i % arrSecond.length];
              if(this.intThisSplit==i){ 
                this.arrThisSplit['DSP'][n] = arrSecond[i % arrSecond.length];
              }
            }
          }else{  // need caculate or replace  if(/^[A-Z:0-9]{0,3}=/.test(value.content) || /<D[1-9][0-9]?>/.test(value.content))
            for(let j = 0;j<this.intSplitTimes;j++){
              let content = value.content;
              if(/<D[1-9][0-9]?>/.test(content)){  // replace dsp marks
                for(let i = 0;i<arrSplitSimDSP.length;i++){  // for simple dsp only use [0] to replace
                  if(arrSplitSimDSP[i]){
                    let i1 = i+1;
                    content = content.replace(RegExp('<D'+i1+'>','g'),arrSplitSimDSP[i][j]); 
                  }
                }
                for(let i = 0;i<arrSplitCalDSP.length;i++){
                  if(arrSplitCalDSP[i]){
                    let i1 = i+1;
                    content = content.replace(RegExp('<D'+i1+'>','g'),arrSplitCalDSP[i][j]); 
                  }
                }
              }      
              if(/^[A-Z:0-9]{0,3}=/.test(content)){  // need calculate
                if(arrSplitCalDSP[n] == undefined){
                  arrSplitCalDSP[n] = new Array;
                }
                if(RegExp("^="+this.strBOD+"$").test(content)){  // only math calculate
                  content = content.replace(/^=/,'');
                  if(this.intThisSplit==j){ // set page show DSP at chosed number
                    this.arrThisSplit['DSP'][n] = math.round(math.evaluate(content),3); 
                  }
                  arrSplitCalDSP[n][j] = math.round(math.evaluate(content),5); 

                }else if(RegExp("^(WOR|ORD|WEK|MON|MOS|ROM|THM|T12|T24)="+this.strBOD+"$").test(content)){ // math calculate (plus other function, not for split table)
                  if(this.intThisSplit==j){ // set page show DSP at chosed number (use format function)
                    this.arrThisSplit['DSP'][n] = this.formatOutput(content);
                  }
                  arrSplitCalDSP[n][j] = this.formatOutput(content);

                }else if(RegExp("^(DC\\w|FAC|ABS)="+this.strBOD+"$").test(content)){ // calculate plus other function)
                  if(this.intThisSplit==j){ // set page show DSP at chosed number (use format function)
                    this.arrThisSplit['DSP'][n] = this.calcOutput(content);
                  }
                  arrSplitCalDSP[n][j] = this.calcOutput(content);

                }else if(RegExp("^(LCM|GCD|MA\\w|MI\\w|POW)="+this.strNums+"$").test(content)){
                  if(this.intThisSplit==j){ // set page show DSP at chosed number (use format function)
                    this.arrThisSplit['DSP'][n] = this.groupOutput(content);
                  }
                  arrSplitCalDSP[n][j] = this.groupOutput(content);

                }else if(RegExp("^(FR\\w|RAT)="+this.strFra+"$").test(this.latexToFrac(content))){  // more # 
                  if(this.intThisSplit==j){ // set page show DSP at chosed number (use format function)
                    this.arrThisSplit['DSP'][n] = this.fracOutput(this.latexToFrac(content));
                  }
                  arrSplitCalDSP[n][j] = this.fracOutput(this.latexToFrac(content));

                }else if(/^ALG=[-+*/()\w\d]+$|^FIR=.+$|^FUP=.+$|^SEL=([^;]+);([^;]+)(:?;([^;]+))?$/.test(content)){
                  if(this.intThisSplit==j){ 
                    this.arrThisSplit['DSP'][n] = this.stringOutput(content);
                  }
                  arrSplitCalDSP[n][j] = this.stringOutput(content);
                }
              }else if(!/^RC:|^OD:|^RD:|^NR:/.test(content)){ // already splited and not need caculate
                if(arrSplitSimDSP[n] == undefined){
                  arrSplitSimDSP[n] = new Array;
                }
                arrSplitSimDSP[n][j] = content;
                // set page show DSP at chosed number
                if(this.intThisSplit==j){ // set page show DSP at chosed number
                  this.arrThisSplit['DSP'][n] = content;
                }
              }
            }
          }
        })
      }

      if(actitems){
        actitems.forEach((value,n)=>{
          if(arrSplitACT[n] == undefined){
            arrSplitACT[n] = new Array;
          }
          for(let j = 0;j<this.intSplitTimes;j++){
            let content = value.content;
            if(/<D\d+>|<A\d+>/.test(content)){  // replace dsp marks
              for(let i = 0;i<arrSplitSimDSP.length;i++){  
                if(arrSplitSimDSP[i]){
                  let i1 = i+1;
                  content = content.replace(RegExp('<D'+i1+'>','g'),arrSplitSimDSP[i][j]); 
                }
              }
              for(let i = 0;i<arrSplitCalDSP.length;i++){
                if(arrSplitCalDSP[i]){
                  let i1 = i+1;
                  content = content.replace(RegExp('<D'+i1+'>','g'),arrSplitCalDSP[i][j]); 
                }
              }
              for(let i = 0;i<arrSplitACT.length;i++){  // replace act marks
                if(arrSplitACT[i]){
                  let i1 = i+1;
                  content = content.replace(RegExp('<A'+i1+'>','g'),arrSplitACT[i][j]); 
                }
              }  
            } 
            if(RegExp("^="+this.strBOD+"$").test(content)){  // // if BODMAS to calcaulate
              content = content.replace(/^=/,'');
              arrSplitACT[n][j] = math.round(math.evaluate(content),5); 
              if(this.intThisSplit==j){ // set page show ACT at chosed number
                this.arrThisSplit['ACT'][n] = math.round(math.evaluate(content),5);
              }
            }else if(RegExp("^(WOR|ORD|WEK|MON|MOS|ROM|THM|T12|T24)="+this.strBOD+"$").test(content)){//other function
              if(this.intThisSplit==j){ // set page show ACT at chosed number (plus other function)
                this.arrThisSplit['ACT'][n] = this.formatOutput(content);
              }
              arrSplitACT[n][j] = this.formatOutput(content);

            }else if(RegExp("^(DC\\w|FAC|ABS)="+this.strBOD+"$").test(content)){//other function
              if(this.intThisSplit==j){ // set page show ACT at chosed number (plus other function)
                this.arrThisSplit['ACT'][n] = this.calcOutput(content);
              }
              arrSplitACT[n][j] = this.calcOutput(content);  //only caculate for split table

            }else if(RegExp("^(LCM|GCD|MA\\w|MI\\w|POW)="+this.strNums+"$").test(content)){
                if(this.intThisSplit==j){ // set page show DSP at chosed number (use format function)
                  this.arrThisSplit['ACT'][n] = this.groupOutput(content);
                }
                arrSplitACT[n][j] = this.groupOutput(content);

            }else if(RegExp("^(FR\\w|RAT)="+this.strFra+"$").test(this.latexToFrac(content))){  // more #
              if(this.intThisSplit==j){ 
                this.arrThisSplit['ACT'][n] = this.fracOutput(this.latexToFrac(content));
              }
              arrSplitACT[n][j] = this.fracOutput(this.latexToFrac(content));

            }else if(/^ALG=[-+*/()\w\d]+$|^FIR=.+$|^FUP=.+$|^SEL=([^;]+);([^;]+)(:?;([^;]+))?$/.test(content)){
                if(this.intThisSplit==j){ 
                  this.arrThisSplit['ACT'][n] = this.stringOutput(content);
                }
                arrSplitACT[n][j] = this.stringOutput(content);

            }else{ //derectly output
              arrSplitACT[n][j] = content;
              // set page show ACT at chosed number
              if(this.intThisSplit==j){ // set page show ACT at chosed number
                this.arrThisSplit['ACT'][n] = content;
              }
            }
          }

        })
      }

      this.arrSplitItems['SimDSP']=arrSplitSimDSP;
      this.arrSplitItems['CalDSP']=arrSplitCalDSP;
      this.arrSplitItems['ACT']=arrSplitACT;
      //console.log('Sim/Cal/Act/thisSplit',arrSplitSimDSP,arrSplitCalDSP,arrSplitACT,this.arrThisSplit);
    },

    fracCalc(input){
      if(/\([^)]*\(/.test(input)){ //Double bracket
        return input;
      }else if(/\((\d+([./]\d+)?([-+*#]\d+([./]\d+)?)*)\)/.test(input)){  //calculate inside bracket
        input = input.replace(/\((\d+([./]\d+)?([-+*#]\d+([./]\d+)?)*)\)/g,(match,p1)=>{
          return this.fracCalcCore(p1);
        });
        return this.fracCalcCore(input);
      }else{
        return this.fracCalcCore(input);
      }
    },

    fracCalcCore(input){
      if(/[-+*#]/.test(input)){  
        let val = 0;
        input = input.replace(/^\d+[./]?\d*/,function(match){
          val = match;
          return '';
        });
        let fra1 = new Frac(val);
        if(input){
          input = input.replace(/(\+|-|\*|#)(\d+[./]?\d*)/g,function(match,p1,p2){
            switch (p1){ 
              case '+': 
                fra1 = fra1.add(p2);
                break;
              case '-': 
                fra1 = fra1.sub(p2);
                break;
              case '*': 
                fra1 = fra1.mul(p2);
                break;
              case '#': 
                fra1 = fra1.div(p2);
            }
            return '';
          });
        }
        return fra1;
      }else{
        return new Frac(input);
      }
    },

    fracOutput(content){ 
      let arrSplited = content.trim().split("=");
      let tmpF = '';
      switch (arrSplited[0]){ //
        case 'RAT':
          tmpF = this.fracCalc(arrSplited[1]).toFraction(false).replace('/',' : ');
          if(!/:/.test(tmpF)){ // if integer only
            tmpF += ' : 1';
          }          
          return tmpF;
        case 'FRA': 
          return this.fracCalc(arrSplited[1]).toFraction(false);
        case 'FRP': 
          return this.fracCalc(arrSplited[1]).toLatex(true);
        case 'FRI': 
          return this.fracCalc(arrSplited[1]).toLatex(false);
        case 'FRR':
          // 18/3/2025 froaction.js 取消不简化设置，FRR改为手工处理，只接受A/B形式
          let arrSplit2 = arrSplited[1].trim().split("/");
          if(arrSplit2.length==2){
            return '\\frac{'+arrSplit2[0]+'}{'+arrSplit2[1]+'}';
          }else{
            return '\\Error';
          }
      }
    },

    latexToFrac(input){
      let output = input.replace(/(\d*)\\frac{(\d+)}{(\d+)}/g,(match,p1,p2,p3)=>{
        if(p1){
          return p1+' '+p2+'/'+p3;
        }else{
          return p2+'/'+p3;
        }
      }); 
      return output; 
    },

    groupOutput(content){ 
      if(!RegExp("^(LCM|GCD|MA\\w|MI\\w|POW|RAN)="+this.strNums+"$").test(content)){ //check leagal 
        return content;
      }
      let arrSplited = content.trim().split("=");
      let arrT = null;
      let arrN = null;
      switch (arrSplited[0]){ //
        case 'RAN':
          arrT = arrSplited[1].split(';');
          return Math.floor(Math.random() * (parseInt(arrT[1]) - parseInt(arrT[0]) + 1) ) + parseInt(arrT[0]);
        case 'POW': 
          arrT = arrSplited[1].split(';');
          return math.pow(arrT[0],arrT[1]);
        case 'LCM': 
          arrT = arrSplited[1].split(';');
          return math.lcm(arrT[0],arrT[1]);
        case 'GCD': 
          arrT = arrSplited[1].split(';');
          return math.gcd(arrT[0],arrT[1]);
        case 'MAX': 
          arrT = arrSplited[1].split(';');
          arrN = Array.from(new Set(arrT))
          arrN.sort(function(a, b){return math.evaluate(b) - math.evaluate(a)});
          return arrN[0];
        case 'MA2': 
          arrT = arrSplited[1].split(';');
          arrN = Array.from(new Set(arrT))
          arrN.sort(function(a, b){return math.evaluate(b) - math.evaluate(a)});
          return arrN[1] ? arrN[1] : '';
        case 'MA3': 
          arrT = arrSplited[1].split(';');
          arrN = Array.from(new Set(arrT))
          arrN.sort(function(a, b){return math.evaluate(b) - math.evaluate(a)});
          return arrN[2] ? arrN[2] : '';
        case 'MIN': 
          arrT = arrSplited[1].split(';');
          arrN = Array.from(new Set(arrT))
          arrN.sort(function(a, b){return math.evaluate(a) - math.evaluate(b)});
          return arrN[0];
        case 'MI2': 
          arrT = arrSplited[1].split(';');
          arrN = Array.from(new Set(arrT))
          arrN.sort(function(a, b){return math.evaluate(a) - math.evaluate(b)});
          return arrN[1] ? arrN[1] : '';
        case 'MI3': 
          arrT = arrSplited[1].split(';');
          arrN = Array.from(new Set(arrT))
          arrN.sort(function(a, b){return math.evaluate(a) - math.evaluate(b)});
          return arrN[2] ? arrN[2] : '';
      }
    },

    stringOutput(content){ 
      if(!/^ALG=[-+*/()\w\d]+$|^FIR=.+$|^FUP=.+$|^SEL=([^;]+);([^;]+)(:?;([^;]+))?$/.test(content)){ //check leagal 
        return content;
      }
      let arrSplited = content.trim().split("=");
      switch (arrSplited[0]){ //
        case 'ALG':   // fix +- problem
          return arrSplited[1].replace('+-','-').replace('--','+').replace(/\+0(?!\.)/,'').replace(/-0(?!\.)/,'');
        case 'FIR':   // first letter
          return arrSplited[1].trim().substring(0,1).toUpperCase();
        case 'FUP':   // first letter upcase
          return arrSplited[1].trim().substring(0,1).toUpperCase() + arrSplited[1].trim().substring(1);
        case 'SEL':   // first letter
          return content.replace(/^SEL=([^;]+);([^;]+)(?:;([^;]+))?$/,(match,p1,p2,p3)=>{
            if(p2){
              let arrText = p1.split(',');
              let arrVal = [];
              let index = arrText.length-1;
              if(p3){
                arrVal = p3.split(',');
              }
              console.log(p2,p3,arrVal)
              if(arrVal.length==arrText.length){
                index = arrVal.indexOf(p2)==-1 ? index : arrVal.indexOf(p2);
              }else if(parseInt(p2)>0){
                index = parseInt(p2)+1>arrText.length ? index : parseInt(p2)-1;
              }
              return arrText[index];
            }else{
              return '';
            }
          }); 
      }
    },

    calcOutput(content){
      if(!RegExp("^(DC\\w|FAC|ABS)="+this.strBOD+"$").test(content)){ //check leagal 
        return content;
      }
      let arrSplited = content.trim().split("=");
      switch (arrSplited[0]){ //
        case 'DCF': 
          return Math.floor(math.evaluate(arrSplited[1]));
        case 'DCC':
          return Math.ceil(math.evaluate(arrSplited[1]));
        case 'DC0': 
          return math.round(math.evaluate(arrSplited[1]));
        case 'DC1': 
          return math.round(math.evaluate(arrSplited[1]),1);
        case 'DC2': 
          return math.round(math.evaluate(arrSplited[1]),2);
        case 'FAC': 
          return math.factorial(math.abs(math.round(math.evaluate(arrSplited[1]))));
        case 'ABS': 
          return math.abs(math.round(math.evaluate(arrSplited[1]),5));
      }
    },

    formatOutput(content){
      if(!RegExp("^(WEK|MON|MOS|ROM|WOR|ORD|THM|T12|T24)="+this.strBOD+"$").test(content)){ //check leagal 
        return content;
      }
      let arrWeekDay = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
      let arrMonth = ['December','January','February','March','April','May','June','July','August','September','October','November'];
      let arrMonthShort = ['Dec','Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov'];
      let arrOrdial = ['th','st','nd','rd','th','th','th','th','th','th'];
      let arrSplited = content.trim().split("=");
      let tmpV = 0;
      let tmpH = '';
      switch (arrSplited[0]){ //
        case 'ROM': 
          return this.numToRoman(math.round(math.evaluate(arrSplited[1])));
        case 'WEK': 
          return arrWeekDay[math.round(math.evaluate(arrSplited[1])+70)%7];
        case 'MON': 
          return arrMonth[math.round(math.evaluate(arrSplited[1])+120)%12];
        case 'MOS': 
          return arrMonthShort[math.round(math.evaluate(arrSplited[1])+120)%12];
        case 'WOR': 
          return this.numToWords(math.round(math.evaluate(arrSplited[1])));
        case 'ORD': 
          tmpV = math.round(math.evaluate(arrSplited[1]));
          if(tmpV%100==11 || tmpV%100==12 || tmpV%100==13 ){
            return '' + tmpV + 'th';
          }else{
            return '' + tmpV + arrOrdial[tmpV % 10];
          }
        case 'THM': 
          tmpV = math.evaluate(arrSplited[1]);
          tmpH = '' + Math.floor(tmpV/60) + ' hour' + (Math.floor(tmpV/60) > 1 ? 's ' : ' ');
          return (Math.floor(tmpV/60)>0 ? tmpH : '') + (tmpV % 60 > 0 ? (tmpV % 60) + ' minute' + (tmpV % 60 > 1 ? 's ' : ' ') : '');
        case 'T12': 
          tmpV = math.evaluate(arrSplited[1]);
          tmpH = Math.floor(tmpV/60) % 24 == 12 ? '12' : (Math.floor(tmpV/60) % 12 < 10 ? '0' : '') + (Math.floor(tmpV/60) % 12);
          return (tmpH + ':' + (tmpV % 60 < 10 ? '0' : '') + (tmpV % 60) + (Math.floor(tmpV/60) % 24 > 11 ? ' pm ' : ' am '));
        case 'T24': 
          tmpV = math.evaluate(arrSplited[1]);
          return (Math.floor(tmpV/60) % 24 < 10 ? '0' : '') + (Math.floor(tmpV/60) % 24) + ':'  + (tmpV % 60 < 10 ? '0' : '') + (tmpV % 60);
      }
    },

    formatAnswer(arrItems){ 
      let arrSort = new Array;
      for(let j=0;j<arrItems.length;j++){
        let arrMate = new Array;
        if(arrItems[j].mate){
          arrMate=arrItems[j].mate.split(";");
        }
        arrItems[j].content_Show = (arrMate[0] || '') + ' ' + this.arrThisSplit['ACT'][j] + ' ' + (arrMate[1] || '');
        arrItems[j].answer_show = arrItems[j].answer;  // not changed
        if(/^[A-Z:0-9]{3}=<[DA][1-9][0-9]?>(;(<[DA][1-9][0-9]?>)?[^;]*)*$/.test(arrItems[j].answer)){
          let answer = [];
          arrItems[j].answer.replace(/^[A-Z:0-9]{3}=(<[DA][1-9][0-9]?>)(?:;((?:<[DA][1-9][0-9]?>)?[^;]*))?$/,(match,p1,p2)=>{
            answer[0] = p1?p1:'';
            answer[1] = p2?p2:'';
          });
          for(let i = 0;i<this.arrSplitItems['CalDSP'].length;i++){  
            if(this.arrSplitItems['CalDSP'][i]){
              let i1 = i+1;
              answer[0] = answer[0].replace(RegExp('<D'+i1+'>','g'),this.arrSplitItems['CalDSP'][i][this.intThisSplit]); 
              answer[1] = answer[1].replace(RegExp('<D'+i1+'>','g'),this.arrSplitItems['CalDSP'][i][this.intThisSplit]); 
            }
          }
          for(let i = 0;i<this.arrSplitItems['ACT'].length;i++){
            if(this.arrSplitItems['ACT'][i]){
              let i1 = i+1;
              answer[0] = answer[0].replace(RegExp('<A'+i1+'>','g'),this.arrSplitItems['ACT'][i][this.intThisSplit]); 
              answer[1] = answer[1].replace(RegExp('<A'+i1+'>','g'),this.arrSplitItems['ACT'][i][this.intThisSplit]); 
            }
          } 
          if(arrItems[j].answer.substring(0,3)=='EQU' ){  // 判断等于功能
            console.log(arrItems[j].answer,answer[0],answer[1])
            if(answer[0]==answer[1]){
              arrItems[j].answer_show = 'Y';
            }else{
              arrItems[j].answer_show = 'N';
            }
          }else if(arrItems[j].answer.substring(0,3)=='GTE' ){  // 判断大于功能
            if(answer[0]>=answer[1]){
              arrItems[j].answer_show = 'Y';
            }else{
              arrItems[j].answer_show = 'N';
            }
          }else if(arrItems[j].answer.substring(0,3)=='LTE' ){  // 判断小于功能
            if(answer[0]<=answer[1]){
              arrItems[j].answer_show = 'Y';
            }else{
              arrItems[j].answer_show = 'N';
            }
          }else if(!RegExp("^"+this.strBOD+"$").test(answer[0])){  // 如果不是可计算字符
            arrItems[j].content_Show = '!!! Compute Error ' + arrItems[j].content_Show;
            answer[0] = 'N';
          }else if(arrItems[j].answer.substring(0,1)=='M'){
            arrSort.push({
              val: answer[0],
              key: arrItems[j].answer
            })
          }
        }
      }

     

      if(arrSort.length>=2){
        arrSort.sort(function(a, b){return math.evaluate(b.val+'-'+a.val)});  //从大到小排序
        for(let j=0;j<arrItems.length;j++){
          switch (arrItems[j].answer.substring(0,3)){ //
            case 'MAX': 
              if(arrItems[j].answer==arrSort[0].key){
                arrItems[j].answer_show = 'Y';
              }else{
                arrItems[j].answer_show = 'N';
              }
              break;
            case 'MA2': 
              if(arrItems[j].answer==arrSort[1].key){
                arrItems[j].answer_show = 'Y';
              }else{
                arrItems[j].answer_show = 'N';
              }
              break;
            case 'MA3': 
              if(arrItems[j].answer==arrSort[2].key){
                arrItems[j].answer_show = 'Y';
              }else{
                arrItems[j].answer_show = 'N';
              }
              break;
            case 'MIN': 
              if(arrItems[j].answer==arrSort[arrSort.length-1].key){
                arrItems[j].answer_show = 'Y';
              }else{
                arrItems[j].answer_show = 'N';
              }
              break;
            case 'MI2': 
              if(arrItems[j].answer==arrSort[arrSort.length-2].key){
                arrItems[j].answer_show = 'Y';
              }else{
                arrItems[j].answer_show = 'N';
              }
              break;
            case 'MI3': 
              if(arrItems[j].answer==arrSort[arrSort.length-3].key){
                arrItems[j].answer_show = 'Y';
              }else{
                arrItems[j].answer_show = 'N';
              }
              break;
          }
        }
      }

      return arrItems;
    },

    numToWords (number) {
      const first = ['','one ','two ','three ','four ', 'five ','six ','seven ','eight ','nine ','ten ','eleven ','twelve ','thirteen ','fourteen ','fifteen ','sixteen ','seventeen ','eighteen ','nineteen '];
      const tens = ['', '', 'twenty','thirty','forty','fifty', 'sixty','seventy','eighty','ninety'];
      const mad = ['', 'thousand', 'million', 'billion', 'trillion'];
      let word = '';

      for (let i = 0; i < mad.length; i++) {
        let tempNumber = number%(100*Math.pow(1000,i));
        if (Math.floor(tempNumber/Math.pow(1000,i)) !== 0) {
          if (Math.floor(tempNumber/Math.pow(1000,i)) < 20) {
            word = first[Math.floor(tempNumber/Math.pow(1000,i))] + mad[i] + ' ' + word;
          } else {
            let conn = Math.floor(tempNumber/Math.pow(1000,i))%10 > 0 ? '-' : ' ';
            word = tens[Math.floor(tempNumber/(10*Math.pow(1000,i)))] + conn + first[Math.floor(tempNumber/Math.pow(1000,i))%10] + mad[i] + ' ' + word;
          }
        }

        tempNumber = number%(Math.pow(1000,i+1));
        if (Math.floor(tempNumber/(100*Math.pow(1000,i))) !== 0) word = first[Math.floor(tempNumber/(100*Math.pow(1000,i)))] + 'hundred ' + word;
      }
        return word.trim();
    },

    numToRoman (num) {
      let lookup ={M:1000,CM:900,D:500,CD:400,C:100,XC:90,L:50,XL:40,X:10,IX:9,V:5,IV:4,I:1};
      let romanStr = "";
      for (let i in lookup){
        while (num >= lookup[i]){
          romanStr+=i;
          num -= lookup[i];
        }
      }
      return romanStr;
    },

    setSplitTable(){
      let iNew = 0;
      this.intSplitErrs = 0;
      for(let i=0;i<this.arrSplitItems['ACT'].length;i++){
        if(this.arrSplitItems['ACT'][i] && ''+this.arrSplitItems['ACT'][i][0]){
          if(this.arrShowSplits[0] == undefined){
            this.arrShowSplits[0]= new Array();
          }
          if(this.arrShowSplits[0][iNew] == undefined){
            this.arrShowSplits[0][iNew] = {};
          }
          this.arrShowSplits[0][iNew].val = 'A'+(i+1);
          for(let j=1;j<=this.intSplitTimes;j++){
            if(this.arrShowSplits[j] == undefined){
              this.arrShowSplits[j]= new Array();
            }
            if(this.arrShowSplits[j][iNew] == undefined){
              this.arrShowSplits[j][iNew] = {};
            }
            this.arrShowSplits[j][iNew].err = false;
            for(let l=0;l<i;l++){
              if(this.arrSplitItems['ACT'][i][j-1] == this.arrSplitItems['ACT'][l][j-1]){
                this.arrShowSplits[j][iNew].err = true;
                this.intSplitErrs ++;
              }
            }
            this.arrShowSplits[j][iNew].val = this.arrSplitItems['ACT'][i][j-1];
          }
          iNew++;
        }
      }
      for(let j=0;j<=this.intSplitTimes;j++){  //spare col
        if(this.arrShowSplits[j] == undefined){
          this.arrShowSplits[j]= new Array();
        }
        if(this.arrShowSplits[j][iNew] == undefined){
          this.arrShowSplits[j][iNew] = {};
        }
        this.arrShowSplits[j][iNew].val = '💡';
      }
      iNew++;
      for(let i=0;i<this.arrSplitItems['CalDSP'].length;i++){
        if(this.arrSplitItems['CalDSP'][i] && ''+this.arrSplitItems['CalDSP'][i][0]){
          this.arrShowSplits[0][iNew]= {};
          this.arrShowSplits[0][iNew].val = 'D'+(i+1);
          for(let j=1;j<=this.intSplitTimes;j++){
            if(this.arrShowSplits[j] == undefined){
              this.arrShowSplits[j]= new Array();
            }
            if(this.arrShowSplits[j][iNew] == undefined){
              this.arrShowSplits[j][iNew] = {};
            }
            this.arrShowSplits[j][iNew].err = false;
            this.arrShowSplits[j][iNew].val = this.arrSplitItems['CalDSP'][i][j-1];
          }
          iNew++;
        } 
      }
      //console.log('arrShowSplits',this.arrShowSplits);
    },

    refreshShow(){
      // trans split data
      this.splitDSPnACT(this.gtdata.dsp_items,this.gtdata.act_items);
      this.gtdata.quest_content_Show = this.calcContent(this.gtdata.quest_content);
      this.gtdata.resolve_Show = this.calcContent(this.gtdata.resolve);
      this.gtdata.act_items = this.formatAnswer(this.gtdata.act_items);

      // init chart data & trans content
      this.chartDisp = [];
      this.canvasDisp = [];
      this.chartAnsw = [];
      this.canvasAnsw = [];
      this.canvasSolv = [];
      this.gtdata.quest_content_Show = marked(this.prepContent(this.gtdata.quest_content_Show,'Q'));
      this.gtdata.resolve_Show = marked(this.prepContent(this.gtdata.resolve_Show,'R'));
      this.gtdata.cap_content = marked(this.prepCloze(this.gtdata.cap_content));

      // loading over and render
      this.loading = false;
      if(this.intSplitTimes>1){
        this.setSplitTable();
      }else{
        this.bolShowPop = false;
      }
      this.MathJax.render(); 
    },

    fixMjSpace(content) {
      content = content.replace(/\$(#|\$).*?\1\$/g,(match)=>{
        match = match.replace(/[ ]+/,' ').replace(/([-+*/=\d}])\\? *((?:hour|hr|minute|min|second|sec|am|pm|a\.m\.|p\.m\.)s?\\? *)(?=[-+*/=\d#$\\])/g,"$1\\ $2\\ ");
        return match;
      })
      return content;
    },

  },

  watch: {
    '$store.state.showpopsp': {
      handler: function(){
        if(this.bolShowPop == true){
          this.bolShowPop = false;
        }
      }
    },

    '$store.state.showdata': {
      handler: function () {
        console.log('Shower received data', this.$store.state.showdata);

        if(Object.keys(this.$store.state.showdata).length){
          this.gtdata = JSON.parse(JSON.stringify(this.$store.state.showdata));
          if(this.gtdata.id>0 || this.gtdata.quest_content){
            this.intThisSplit = 0;     
            this.refreshShow();
          }else{
            this.loading = true;
          }
        }
      },
      deep: true
    },


  }
}

</script>

<style scoped>
#questShow {
  width: calc(100% - 12px);
  height: calc(100vh - 16px);
  margin: 8px 8px;
}

#popSplit {
  position: fixed;
  z-index: 99;
  left: 210px;
  top: 32px;
  width: 540px;
  height: calc(100vh - 48px); 
  margin: 8px 8px;
  padding: 16px;
  overflow: auto; 
  border-width: 1px;
  border-style: solid; 
  border-color: lightgray;   
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.258824),0 2px 6px 0 rgba(0,0,0,0.156863);
  background-color: white;
  text-align: center;
}
#btnSpShow {
  position: fixed;
  right: 26%;
  bottom: 30px;
}
#tbcenter {
  margin: auto;

}

.slcttr:hover{
  border: 1px dashed #03A9F4;
  cursor:pointer;
}

.slctdiv {
  font-size: 18px;
  padding: 10px;
  margin: 3px 0;
  background: #E1F5FE;
  border: 3px solid transparent;
  display: flex;
}
.slctdiv:hover{
  border: 3px dashed #03A9F4;
  cursor:pointer;
}
.isslcted {
  background: #4FC3F7;
}

.div_main{
  width: 100%;
  height: calc(100vh - 48px); 
  border-width: 1px;
  border-style: solid; 
  border-color: lightgray;   
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.258824),0 2px 6px 0 rgba(0,0,0,0.156863);
  background-color: white;
}
.div_midn{
  width: 100%;
  height: calc(100vh - 52px);    
  display: flex;
  flex-wrap: wrap;
}
.div_left{
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;   
  border-radius: 0 0 0 7px;
}
.div_right{
  width: 38%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto; 
  border-width: 0 0 0 1px;
  border-style: solid; 
  border-color: lightgray;    
  border-radius: 0 0 7px 0;
}

.div_cont{
  margin-top: 4px;
}

.div_item{
  color: #000;
  padding: 0 15px;
  text-align: justify;
  justify-content: flex-start;
}

.div_answBox{
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}
.div_answItem{
  padding: 4px;
}
.div_answLabl{
  display: flex;
  justify-content: center;
}
.div_answCanv{
  padding: 2px;
  background: #E1F5FE;
  border: 3px solid transparent;
  border-radius: 8px;
}
.div_answCanv:hover{
  border: 3px dashed #03A9F4;
  cursor:pointer;
}
.answSlcted {
  background: #4FC3F7;
}

.slut_title {
  margin: 20px 0 20px 0;
  display: flex;
  text-align: justify;
  justify-content: center;
}
.slut_title .le, .slut_title .ri {
  width: 40%;
  height: 16px;
  border-width: 0 0 4px 0;
  border-style: double; 
  border-color: #BDBDBD;    
}
.slut_title .mi{
  text-align: center;
  width: 100px;
  line-height: 24px;
  font: normal 20px Arial Black;
  color: #1E88E5;
}
.slut p{
  font-size: 18px;
  color: #424242;
}

.tbsp {
  font-size: 14px;
  border-collapse: collapse;
}
.tbsp tr:nth-child(even){
  background-color: #E1F5FE;
}
.tbsp th{
  font-size: 14px;
  padding: 2px 2px;
  border: 1px solid #666666;
  background-color: #E1F5FE;
}
.tbsp td{
  font-size: 14px;
  padding: 2px 2px;
  border-width: 1px;
  border-style: solid;
}
</style>
<style>
body div .div_item p, body div .div_item li{
  font-size: 20px;
  color: #333;
  margin: 8px 0;
}
.div_item li p{
  margin: 0;
}
h1 {
  font-size: 24px;
} 
h2 {
  font-size: 21px;
}
h3 {
  font-size: 18px;
}
.tb1 {
  font-size: 16px;
  border-collapse: collapse;
  margin: 0 0 20px 0;
}
.tb1 tr:nth-child(odd){
  background-color: #E1F5FE;
}
.tb1 th{
  padding: 4px 8px;
  border: 1px solid #666666;
  background-color: #E1F5FE;
}
.tb1 td{
  padding: 4px 8px;
  border-width: 1px;
  border-style: solid;
}

.tb9 {
  font-size: 16px;
  border-collapse: collapse;
  margin: 0 0 20px 0;
}
.tb9 th{
  padding: 4px 8px;
  border: 1px solid transparent;
}
.tb9 td{
  padding: 4px 8px;
  border: 1px solid transparent;
}

.dv1 {
  margin: 0 0 20px 0;
  padding: 0px 12px 0px 12px;
  border: 1px solid #666;
}

quotespan {
  padding: 8px 4px;
  background-color: #E1F5FE;
  border-radius: 4px;
  line-height: 24px;
}
quotespan:before {
  color: #392570;
  content: "\201C";
  font-size: 42px;
  position: relative;;
  left: -4px;
  top: 16px;
}
quotespan:after {
  color: #392570;
  content: "\201D";
  font-size: 42px;
  position: relative;
  right: -4px;
  bottom: -16px;
}

.splitspan {
  padding: 1px 3px 1px 3px;
  background-color: #FFECB3;
  border-radius: 4px;
}
.clozeNumC {
  padding: 1px 3px 1px 3px;
  color: white;
  background-color: #1E88E5;
}
.clozeDotC {
  padding: 0px 3px 0px 3px;
  border: 1px solid #1E88E5;
}
</style>
