<template>
  <div class="wrtlist">
    <div id="usernotes" v-show="shownotes">
      <b>ID:</b>{{noteitem.id}}&nbsp;
      <span @dblclick="chgLang(noteitem.id,noteitem.idx)" v-if="noteitem.lang==1" style="font-size:12px;color:black;background-color: pink;border:3px pink solid;">CN</span>
      <span @dblclick="chgLang(noteitem.id,noteitem.idx)" v-if="noteitem.lang==2" style="font-size:12px;color:white;background-color: blue;border:3px blue solid;">EN</span>
      {{noteitem['student.plain']?'⬇️':''}}
      {{noteitem['student.other']?'🌙':''}}
      &nbsp;<b>R:</b>{{noteitem.created_at?new Date(noteitem.created_at).toLocaleDateString():''}}
      &nbsp;<b>B:</b>{{noteitem.buy_at?new Date(noteitem.buy_at).toLocaleDateString().replace(/01\/01\/1980/,' --'):''}}
      &nbsp;<b>V:</b>{{noteitem.visit_at?new Date(noteitem.visit_at).toLocaleDateString():''}}
      &nbsp;<b>P:{{noteitem.credit}}</b>
      &nbsp;<span @dblclick="chgNote(1)" style="font-size:13px;color:gray;background-color: #fad7a0;border:3px #fad7a0 solid;"> ♣</span>
      &nbsp;<span @dblclick="chgNote(2)" style="font-size:13px;color:gray;background-color: #fad7a0;border:3px #fad7a0 solid;">】</span> 
      <br><span style="background-color: #ddd;">{{noteitem.name}}</span>&nbsp;
      {{noteitem.email}}&nbsp;
      <span style="background-color: #ddd;">{{noteitem.mobile}}</span>
      {{noteitem.address}} {{noteitem.suburb}}
      <br><span style="background-color: #ddd;">{{noteitem['student.name']}}</span>
      {{noteitem['student.block']==4?'⛔':noteitem['student.block']==3?'⭕':noteitem['student.block']==2?'🔒🔒':noteitem['student.block']==1?'🔒':''}}
      {{noteitem['student.alert']>2?('❗'+arrNums[noteitem['student.alert']]+'❗'):noteitem['student.alert']==2?'❗❗':noteitem['student.alert']==1?'❗':noteitem['student.alert']==(-1)?'🌵':''}}
      <span @dblclick="mock(noteitem['student.fbuid'])">{{noteitem['student.loginname']}}</span>
      {{Math.floor(noteitem['student.pall']/100%10)>0?' ❰pc'+Math.floor(noteitem['student.pall']/100%10)+'❱':''}}
      {{Math.floor(noteitem['student.pall']/1000%10)>0?' ❰oc'+Math.floor(noteitem['student.pall']/1000%10)+'❱':''}}
      {{Math.floor(noteitem['student.pall']/10000%10)>0?' ❰ps'+Math.floor(noteitem['student.pall']/10000%10)+'❱':''}}
      {{Math.floor(noteitem['student.pall']/100000%10)>0?' ❰ss'+Math.floor(noteitem['student.pall']/100000%10)+'❱':''}}
      {{Math.floor(noteitem['student.pall']%100)>0?' ❰rt'+Math.floor(noteitem['student.pall']%100)+'❱':''}}
      &nbsp;&nbsp;<span style="background-color: #ddd;">{{noteitem['student.gender']}}</span>
      &nbsp;&nbsp;<b>Yr</b>{{noteitem['student.grade']}}
      <span v-if="noteitem['student.doidt']==2">⛺</span>
      &nbsp;&nbsp;<b>T:</b>{{new Date(noteitem['student.tested_at']).toLocaleDateString()}}
      &nbsp;&nbsp;<b>⭐{{noteitem['student.score']}} / {{noteitem['student.avg5']}}</b>
      <br v-if="noteitem.sib"><span v-if="noteitem.sib">{{noteitem.sib}}</span>    
      <a-textarea v-model:value="noteitem.notes" :rows="8" style="margin-bottom:10px;resize: none;" />
      <a-button size="small" type="primary" @click="upNotes(1)" style="margin-right:10px;">Update</a-button>
      <a-button size="small" @click="upNotes(0)" style="margin-right:10px;">Cancel</a-button>
      <span v-if="Object.keys(location).length" style="color: #666;">&nbsp;&nbsp;<b>IP</b>: {{ noteitem.ip }} - {{ location.city }} -  
      {{ location.country_name=='Australia'?location.region_code:location.country_name}} - {{ location.org }}</span> 
    </div>
    <div id="wrtdetails" v-show="showwriting">
      <div class="wrt_row">
        <div><b>ID:</b> {{writingitem.id}}</div>
        <div><b>Status:</b> {{writingitem.status}}</div>
        <div><b>Dur:</b> {{writingitem.tmins>0?writingitem.tmins:''}} mins</div>
        <div><b>Score:</b> <span v-if="writingitem.status!=4">{{writingitem.score}}  </span>    
          <a-input v-if="writingitem.status==4" v-model:value="writingitem.score" @change="changeScore" size="small" style="width: 50px;"/> / 30
        </div>
        <div><b>Offset:</b> {{writingitem.offset}}</div>
        <div><b>Allscore:</b> {{writingitem.allscore}}</div>
        <a-button v-if="writingitem.status==4 && !writingitem.resp" size="small" type="primary" @click="aiscoring" style="margin-right:10px;">AI Scoring</a-button>
        <a-button v-if="writingitem.status==4 && writingitem.feedback && writingitem.feedback.length>500" size="small" type="primary" @click="savescoring" style="margin-right:10px;">Save Data</a-button>
        <a-button size="small" @click="showwriting=false" style="margin-right:10px;">Cancel</a-button>
      </div>
      <div class="wrt_row">
        <div class="wrt_left" v-html="taskandcontent"></div>
        <a-textarea class="wrt_right" v-model:value="writingitem.feedback" style="margin-bottom:10px;resize: none;" />
      </div>
    </div>
    <div class="pageline">
      <div @click="onChange(item)" v-for="(item) in arrPageNum" :key="'pg'+item" 
       :class="(currentpage==item)?'pagetab bk_selpg':'pagetab'">{{item}}
      </div>
    </div>
    <div class="pageline">
      Task:<a-input v-model:value="ipttask" size="small" style="width: 80px;"/>
      <a-checkbox style="margin-left:40px;" v-model:checked="check[0]" @change="search(0,1)">SS</a-checkbox>
      <a-checkbox v-model:checked="check[1]" @change="search(0,2)">PS</a-checkbox>
      <a-input v-model:value="iptstudent" size="small" style="width: 120px;margin-left:10px;margin-right:10px;"/>
      <a-button v-if="iptstudent!=''" size="small" type="primary" @click="search(1)" style="margin-left:10px;margin-right:10px;">Search Student</a-button>
      <a-button size="small" @click="search(2)" style="margin-right:10px;">All Students</a-button>
      <span style="color:#999;">[!@#$]from(-to):days(60)</span>
    </div>    
    <table class="tb88">
      <tr style="text-align:center;font-size:15px;font-weight:bold;">
        <td></td><td>ID</td><td>Task</td><td>Start Time</td><td>Dur</td><td>Status</td><td>Student</td><td>Yr</td><td>Type</td>
        <td>Num</td><td>Score</td><td>Offset</td><td>Allscore</td>
      </tr>
      <tr :class="item.status==1?'cl_doing':item.status==0?'cl_wait':item.status==4?'cl_manual':'cl_done'" 
          :style="(!singlemode && selectname==item['student.loginname'])?lockname?'background-color:#FFD54F;':'background-color:#FFECB3;':''" 
          v-for="(item,index) in writings" :key="'or'+index"
          @click="lockdoo(item['student.loginname'])"
          @mouseover="selectname=lockname?selectname:item['student.loginname']" 
          @mouseleave="selectname=lockname?selectname:''">
        <td>{{(index%5==4 && index<999)?index+1:''}}</td> 
        <td>{{item.id}}</td>    
        <td>{{item.tk_id}}</td>    
        <td>
          {{formatSydneyTime(item.started_at)}}
        </td>
        <td :style="'text-align: center;'">{{item.tmins>0?item.tmins:''}}</td>
        <td @click="writingitem=item,writingitem.index=index,showwriting=true">{{item.status==1?'Doing':item.status==2?'Done':item.status==3?'Done-M':item.status==4?'Manual':'Wait'}}</td>
        <td class="td_name">{{item['student.block']==4?'⛔':item['student.block']==3?'⭕':item['student.block']==2?'🔒🔒':item['student.block']==1?'🔒':''}}{{item['student.alert']>2?('❗'+arrNums[item['student.alert']]+'❗'):item['student.alert']==2?'❗❗':item['student.alert']==1?'❗':item['student.alert']==(-1)?'🌵':''}}
        <span @dblclick="showNotes(item['student.loginname'])">{{item['student.loginname']}}</span></td>
        <td :style="'text-align: center;'">{{item['student.grade']}}</td>
        <td :style="'text-align: center;'">{{item.belong}}</td>
        <td :style="'text-align: center;'">{{item.test_num}}</td>
        <td :style="'text-align: right; padding-right: 8px;'">{{(item.status==2 || item.status==3)?item.score:''}}</td>
        <td :style="'text-align: right; padding-right: 8px;' + (item.offset<0?' color: red;':'')">{{item.offset!=0?item.offset:''}}</td>
        <td :style="'text-align: right; padding-right: 8px;'">{{item.allscore>0?item.allscore:''}}</td>
      </tr>    
    </table>
    <div class="pageline">
      <div @click="onChange(item)" v-for="(item) in arrPageNum" :key="'pg'+item" 
       :class="(currentpage==item)?'pagetab bk_selpg':'pagetab'">{{item}}
      </div>
    </div>
    <div class="pageline">
      Task:<a-input v-model:value="ipttask" size="small" style="width: 80px;"/>
      <a-checkbox style="margin-left:40px;" v-model:checked="check[0]" @change="search(0,1)">SS</a-checkbox>
      <a-checkbox v-model:checked="check[1]" @change="search(0,2)">PS</a-checkbox>
      <a-input v-model:value="iptstudent" size="small" style="width: 120px;margin-left:10px;margin-right:10px;"/>
      <a-button v-if="iptstudent!=''" size="small" type="primary" @click="search(1)" style="margin-left:10px;margin-right:10px;">Search Student</a-button>
      <a-button size="small" @click="search(2)" style="margin-right:10px;">All Students</a-button>
    </div>  
  </div>
</template>

<script>
import { Geter,Puter } from '@/utils/request.js';
import swal from 'sweetalert2';
import { marked } from 'marked';
var rendererMD = new marked.Renderer();
marked.use({
  mangle: false,
  headerIds: false
});
marked.setOptions({
  renderer: rendererMD,
  gfm: true,
  tables: true,
});

export default {
  name:'writings',
  data () {
    return {
      writings: [],
      selectname: '',
      lockname: false,
      totalpages: 1,
      currentpage: 1,
      arrPageNum: [],
      check: [false,false],
      belong: '',
      ipttask: '',
      iptstudent: '',
      singlemode: false,
      arrNums: ['','①', '②', '③', '④', '⑤', '⑥', '⑦', '⑧', '⑨', '⑩', '⑪', '⑫', '⑬', '⑭', '⑮'],
      shownotes: false,
      noteitem: {},
      location: {},
      showwriting: false,
      writingitem: {},
      windowWidth: document.documentElement.clientWidth, 

    };
  },

  components: {},

  computed: {
    taskandcontent() {
      return marked('### <font color=blue>┉┉┉┉┉┉┉ Task given ┉┉┉┉┉┉┉</font>\n'+
      this.writingitem.task +'\n\n### <font color=blue>┉┉┉┉┉ Student’s Writing ┉┉┉┉┉┉</font>\n'+
      this.writingitem.content);
    }
  },

  beforeMount() {},

  mounted() {
    this.getWrtList();
    window.onresize = () => {
      return (() => {
        let fullWidth = document.documentElement.clientWidth;
        this.windowWidth = fullWidth; 
      })();
    };
  },

  methods: {
    formatSydneyTime(time) {
      const formatter = new Intl.DateTimeFormat('en-AU', {
        weekday: 'short',    
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      });
      return formatter.format(new Date(time));
    },

    changeScore() {
      let score30 = this.writingitem.allscore / 120 * 30;  // 三科考分折算30分
      let offsetBase = score30 > 21 ? (21 * 2 + score30) / 3 : score30 < 15 ? (15 + score30) / 2 : score30;
      let writingOffset = this.writingitem.score - offsetBase;
      if(writingOffset + this.writingitem.allscore > 120){  // 修正可能总分超120的bug，但实际可能不大
        writingOffset = 120 - this.writingitem.allscore;
      }
      this.writingitem.offset = writingOffset.toFixed(1);
    },

    async aiscoring() {
      let putData = {};
      putData.id = this.writingitem.id;
      try {
        let response = await Puter(`scoringwrt`, putData);
        if (response.data.code==202) {
          this.writingitem.score = response.data.score;
          this.writingitem.offset = response.data.offset;
          this.writingitem.feedback = response.data.feedback;
          this.writingitem.resp = response.data.resp;
        }
      }catch(e) {
        console.log(e);
      }
    },

    async savescoring() {
      let putData = {};
      putData.id = this.writingitem.id;
      putData.score = this.writingitem.score;
      putData.offset = this.writingitem.offset;
      putData.feedback = this.writingitem.feedback;
      putData.resp = this.writingitem.resp;
      try {
        let response = await Puter(`savescoring`, putData);
        if (response.data.code==202) {
          this.writingitem.status = 3;
          //this.writings[this.writingitem.index] = this.writingitem;
        }
      }catch(e) {
        console.log(e);
      }
    },

    async getWrtList(topg=0) {
      try {
        this.iptstudent = this.iptstudent.trim();
        let response = await Geter(`wrtlist`,{pg:topg,bl:this.belong,st:this.iptstudent,tk:this.ipttask});
        if (Object.keys(response.data).length && response.data!='Access Denied'){
          this.writings = response.data.rows;
          this.totalpages = Math.ceil(response.data.count/200);
          this.totalpages = this.totalpages>999?999:this.totalpages;
          this.arrPageNum = [];
          this.currentpage = 1 + parseInt(response.data.pg);
          for(let i=1;i<=this.totalpages;i++){
            if(Math.abs(this.currentpage-i)<5){
              this.arrPageNum.push(i);
            }else if(Math.abs(this.currentpage-i)<50 && i%10==0){
              this.arrPageNum.push(i);
            }else if(i%100==0){
              this.arrPageNum.push(i);
            }else if(i==1 || i==this.totalpages){
              this.arrPageNum.push(i);
            }
          }
        }
      }catch(e) {
        console.log(e);
      }
    },

    async mock(stu){
      console.log(stu);
      try {
        let response = await Geter(`mockstudent`,{st:stu});
        if (response.data==201){
          swal.fire({text: 'Successfully imitating the student!',
          timer: 3000,
          showConfirmButton: false});
        }
      }catch(e) {
        console.log(e);
      }
    },

    onChange(topg) {
      this.getWrtList(topg);
    },

    lockdoo(stu){
      if(!this.singlemode){
        this.lockname = !this.lockname;
        if(this.lockname){
          this.iptstudent = stu;
        }else{
          this.iptstudent = '';
        }
      }
    },

    search(sg=0,bl=0) {
      let arrbl = ['SS','PS']
      if(bl>0 && this.check[bl-1]){
        for(let i=0;i<=1;i++){
          if(i!=(bl-1)){
            this.check[i]=false;
          }
        }
        this.belong = arrbl[bl-1];
      }else{
        this.belong = '';
      }
      if(sg==1){
        this.singlemode = true;
        for(let i=0;i<=1;i++){
          if(this.check[i]){
            this.belong = arrbl[i];
          }
        }
      }
      if(sg==2){
        this.singlemode = false;
        this.iptstudent = '';
        for(let i=0;i<=1;i++){
          if(this.check[i]){
            this.belong = arrbl[i];
          }
        }
      }
      this.getWrtList();
    },

    async showNotes(loginname){
      if(loginname){
        this.location = {}
        try {
          let response = await Geter('shownote',{ln:loginname});
          if(response && response.data.code==201){
            this.shownotes = true;
            this.noteitem = response.data.item;
            let ipAddress = this.noteitem.visit_ip.split(',')[0];
            this.noteitem.ip = ipAddress.substring(0,20);
            let res = await Geter(`https://ipapi.co/${ipAddress}/json/`)
            this.location = res.data;
          }
        }catch(e) {
          console.log(e);
        }
      }
    },

    async upNotes(type){
      if(type){
        try {
          let response = await Puter('upnote',{id:this.noteitem.id,notes:this.noteitem.notes.trim()});
          if(response && response.data==201){
            this.shownotes = false;
            this.noteitem = {};
            this.getWrtList(this.currentpage);
          }else{
            swal.fire({text: 'Update failed! To clear please type "Del".',
            timer: 3000,
            showConfirmButton: false});
          }
        }catch(e) {
          console.log(e);
        }
      }else{
        this.shownotes = false;
        this.noteitem = {};
        this.getWrtList(this.currentpage);
      }
    },

    chgNote(n) {
      if(n==1){
        this.noteitem.notes = '♣' + this.noteitem.notes;
      }else if(n==2){
        this.noteitem.notes = this.noteitem.notes + '【】';
      }
    },

    async chgLang(id,idx){
      try {
        let response = await Geter('chglang',{id:this.noteitem.id,notes:this.noteitem.notes.trim()});
        if(response && response.data==201){
          this.noteitem.lang = 3 - this.noteitem.lang;
          this.users[idx].lang = this.noteitem.lang;
        }
      }catch(e) {
        console.log(e);
      }
    },
  },

}

</script>
<style scoped>
.prog{
  position: relative;
  left: 0px;
  top: 0px;
  margin-bottom: -25px;
}

#usernotes{
  position: fixed;
  z-index: 99;
  left: 10%;
  top: 30%;
  width: 580px;
  height: 340px; 
  margin: 8px 8px;
  padding: 10px;
  overflow: none; 
  border-width: 1px;
  border-style: solid; 
  border-color: lightgray;   
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.258824),0 2px 6px 0 rgba(0,0,0,0.156863);
  background-color: white;
  text-align: left;
}
#wrtdetails{
  position: fixed;
  z-index: 99;
  left: 5%;
  top: 5%;
  width: 80%;
  max-width: 1200px;
  height: 80%;
  max-height: 600px; 
  margin: 8px 8px;
  padding: 10px;
  overflow: none; 
  border-width: 1px;
  border-style: solid; 
  border-color: lightgray;   
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.258824),0 2px 6px 0 rgba(0,0,0,0.156863);
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.wrt_row{
  display: flex;
  height: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.wrt_left{
  width: 55%;
  height: 95%;
  padding: 10px;
  border: 1px solid lightblue;
  overflow-y: scroll;
}
.wrt_right{
  width: 45%;
  height: 95%;
}
.wrtlist{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
}
.pageline{
  margin: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-flow: wrap;
}
.pagetab{
  margin: 0 4px;
  width: 25px;
  height: 20px;
  text-align: center;
  border: 1px solid #999;  
  border-radius: 3px;  
}
.pagetab:hover{
  cursor:pointer;
  opacity: 0.85;
  box-shadow: 1px 1px 0 1px #666,
              1px 1px 2px 1px #999;
}
.bk_selpg{
  background-color: #FFD54F;
}


.tb88  {
  width: 95%;
  margin: 12px;
  word-break: break-all;
}
.tb88 tr td{
  border: 1px solid black;
}
.cl_doing{
  color: #e33;
}
.cl_done{
  color: #222;
}
.cl_manual{
  color: rgb(131, 26, 180);
}
.cl_wait{
  color: rgb(101, 201, 101);
}
.td_name:hover{
  cursor:pointer;
}

.act_botton {
  font-size: 13px;
  line-height: 18px;
  border-width: 1px;
  border-style: solid;    
  border-color: #666;   
  border-radius: 16px;
  padding: 2px 8px 2px 8px;
  margin: 0 4px 0 4px;
  color: white;
  text-shadow: 0 0 1px #000;
  background: #7d9dda;
}
.act_botton:hover{
  cursor:pointer;
  opacity: 0.85;
  box-shadow:1px 1px 2px #666;
}

@media screen and (max-width: 1000px) {
  *,textarea,button,span {
    font-size: 12px;
  }
  #usernotes{
    left: 1%;
    top: 25%;
    font-size: 10px;
    width: 400px;
    height: 300px; 
    padding: 8px;
  }
}
</style>